@import url('https://fonts.googleapis.com/earlyaccess/cwtexkai.css'); /* 楷體 */
@import url('https://fonts.googleapis.com/earlyaccess/cwtexyen.css'); /* 圓體 */
@import url('https://fonts.googleapis.com/earlyaccess/notosanstc.css'); /* 黑體 */
@import url('https://fonts.googleapis.com/css2?family=Fredoka&family=LXGW+WenKai+Mono+TC:wght@300;400;700&display=swap'); /* 'LXGW WenKai Mono TC'*/
@import url('https://fonts.googleapis.com/css2?family=Playwrite+NZ:wght@100..400&display=swap'); /* Playwrite New Zealand */
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap'); /* Fredoka */

html, body {
  font-family: Arial, sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #e8e1d4; /* 背景色 */
}

.App {
  /* padding: 20px; */
  max-width: 100%;
  margin: auto;
  /* min-height: 100%; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* fixed bottom-0 top-0 */
}

.header {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}

.mainBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* max-width: 1000px; */
}

.ad {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100px;
  /* display: none; Hide ad on mobile */
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  /* flex-grow: 1; */
  justify-content: space-around;
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 3px solid #f7b87e; /* 次色調邊框 */
  border-radius: 10px 10px 0 0; /* 圓角 */
}

.tablinks {
  background-color: #f7b87e; /* 次色調背景 */
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  margin-right: 5px;
  flex: 1 0 40%;
  /* flex: 1; */
  text-align: center;
  color: #512703;
  font-weight: bold;
  border-radius: 10px; /* 圓角 */
  transition: background-color 0.3s ease;
}

.tablinks:hover {
  background-color: #f09f56; /* 主色調 */
}

.tablinks.active {
  background-color: #f09f56; /* 活動時背景色 */
}

.tabcontent,
.activity {
  display: block; /* 預設顯示內容 */
  padding: 10px 20px;
  border-top: none;
  background-color: #efceae; /* 白色背景 */
  border-radius: 0 0 10px 10px; /* 圓角 */
}

.activity-hintText {
  font-weight: bold;
  color: indianred
}

.bulletin-board {
  display: block; /* 預設顯示內容 */
  padding: 10px 20px;
  background-color: #efceae; /* 背景 */
  border-radius: 10px 10px 0px 0px; /* 圓角 */
  color: rgba(60, 34, 10, 0.745);
  margin-bottom: 5px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  /* font-size: 2vmin */
}

.input-row .hint {
  font-weight: 500;
  color: #ec5353;
  margin: 0px 5px;
  /* text-decoration: unline-through; */
}

.input-row {
  width: 100%;
  /* margin-top: 5px; */
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center; /* Align items to the center */
}

.input-container {
  padding: 0px 10px;
  display: flex;
  flex-direction: column; /* Ensure error message is below input */
  flex: 1 0 100%;
  /* flex: 1; */
}

.input-row input {
  /* margin-top: 10px; */
  margin-right: 10px;
  padding: 5px;
  width: calc(100% - 130px);
  height: 25px;
  border: 2px solid #f7b87e; /* 次色調邊框 */
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.input-row .error-hint{
  color: #ec5353;
  font-size: 0.9em;
  margin: 0px 5px;
}

.paste-icon,
.save-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-size: 20px;
  padding: 3px;
  margin: 5px;
  color: #f7b87e;
  cursor: pointer;
  transition: color 0.3s ease, border-color 0.3s ease;
  border: 2px solid #f7b87e;
  border-radius: 20%;
}

.paste-icon:hover,
.save-icon:hover {
  color: #e69b4c; /* 滑鼠懸停時的顏色變化 */
  border-color: #e69b4c;
}

.link-notification {
  margin-top: 10px;
  padding: 10px;
  color: #635e55;
  font-size: 1.1rem;
  /* background-color: rgba(247, 184, 126, 0.6); */
  border-radius: 4px;
  color: #ec5353;
}


.error-message {
  color: #ec5353; /* 錯誤訊息顏色 */
  font-size: 0.9em;
  margin-top: 5px;
}

.subtabs {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

.subtablinks {
  background-color: #f7b87e; /* 次色調背景 */
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 8px;
  /* flex: 1 0 40%; */
  flex: 1 0 calc(50% - 50px);
  text-align: center;
  color: #512703;
  font-weight: bold;
  border-radius: 10px; /* 圓角 */
  transition: background-color 0.3s ease;
}

.subtablinks:hover {
  background-color: #f09f56; /* 主色調 */
}

.subtablinks.active {
  background-color: #f09f56; /* 活動時背景色 */
}

.link-status-fold,
.link-history-fold {
  width: calc(100% - 20px);
  /* margin-bottom: 10px; */
  padding: 6px;
  color: #635e55;
  font-weight: 500;
  /* font-size: 0.9rem; */
  border: none;
  background-color: rgba(247, 184, 126, 0.6);
  border-radius: 10px;

}

.status-fold-button,
.history-fold-button {
  margin: 3px;
}

.link-history {
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  /* padding-left: 1.5rem; */
  margin: 10px 0px;
}

.link-history.expanded {
  max-height: 500px;
  opacity: 1;
}

.link-history.collapsed {
  max-height: 0;
  opacity: 0;
}

.link-status-item{
  margin: 10px 0px;
  font-family: "Fredoka", sans-serif;
  font-weight: 500;
  color: #635e55;
  white-space: nowrap; /* 防止連結文字換行 */
  overflow: hidden; /* 隱藏超出容器的文字 */
  text-overflow: ellipsis; /* 以省略號顯示過長文字 */
  max-width: 100%; /* 限制寬度為父容器的 100% */
}

.link-status{
  margin: 0px 10px;
  padding: 2px 15px;
  border-radius: 20px ;
  font-size: 0.9rem;
}

.link-status.clicked{
  color: #819787;
  background-color: #dbeddb;
}

.link-status.unclick{
  color: #b56f68;
  background-color: #ffe2dd;
}

.status-refresh{
  font-size: 1.5rem;
  font-weight: bold;
  color: gray;
}

.status-refresh:hover {
  color: #635e55;
  cursor: pointer;
}

.linktooltip {
  font-size: 14px;
  max-width: 80%; /* 限制最大寬度，確保在小螢幕上不會超出畫面 */
  word-wrap: break-word; /* 允許單詞在必要時換行 */
  white-space: normal; /* 讓內容可以換行 */
}

.link-history-item {
  font-weight: 500;
  font-family: "Fredoka", sans-serif;
  transition: color 0.5s, opacity 0.5s;
}

.link-history-item:nth-child(1),
.link-history-item:nth-child(2),
.link-history-item:nth-child(3)
{
  transform: translateY(-20px); /* 上移效果 */
  transition: transform 0.5s, opacity 0.5s;
}

.link-history.expanded .link-history-item {
  transform: translateY(0); /* 恢復原位 */
}

.linkWaitWord{
  font-Size: 16;
  margin: 10px;
  /* margin-top: 20px; */
}

.footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  background-color: #e8e1d4;
  border-top: 1.5px solid #DBD1BD;
  /* max-width: 100%; */
  /* height: 40px; */
  /* text-align: center; */
  /* margin-top: auto; */
  padding: 10px 0;
}

.footer-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding:30px 20px;
}

.footer-left,
.footer-right {
  width: 100%;
  text-align: center;
  flex-direction: column;
  margin: 5px;
}

.footer-left {
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 0.9rem;
  color: #635e55;
  flex-direction: column;
  padding: 10px;

}

.footer-logo {
  display: flex;
  justify-content: center;
  font-family: "Playwrite NZ", cursive;
  font-optical-sizing: auto;
  color: #f09f56;
  font-weight: bold;
}

.brand-logo {
  width: 50px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.footer-email-text {
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 0.9rem;
  color: #635e55;
}

.footer-right span {
  font-size: 1.2em;
  font-family: 'Noto Sans TC', sans-serif;
  text-shadow: 1px 2px 2px gray;
}

.copyright {
  display: flex;
  justify-content: center;
  color: #635e55;
  font-size: 12px;
  margin: 10px 0px;
}

.footer-button-container {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.donate-button {
  background-color: #f7b87e;
  color: #635e55;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.donate-button:hover {
  background-color: #f09f56;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #f4eee0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  max-width: 400px;
  width: 80%;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 40px;
  cursor: pointer;
}

.qr-code-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.qr-code-item {
  text-align: center;
}

.qr-code {
  width: 150px;
  height: 150px;
}

/* 彈幕 */
.barrage-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1001; /* Above modal */
  overflow: hidden;
}

.barrage-message {
  position: absolute;
  white-space: nowrap;
  color: gray;
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Noto Sans TC', sans-serif;
  animation: barrageAnimation 3s linear forwards;
}

@keyframes barrageAnimation {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.marquee-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 9999;
  transform: translateX(100%);
  transition: transform 0.5s ease-out;
}

.marquee-container.modalOpen {
  transform: translateX(0);
}

.marquee-container.closing {
  animation: slideLeft 4s linear forwards;
}

.marquee-animation {
  position: absolute;
  display: flex;
  align-items: center;
  white-space: nowrap;

}

.top-animation {
  top: 0;
  right: 0;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-animation {
  bottom: 25%;
  right: 0;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-animation {
  bottom: -0.5%;
  right: 0;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.marquee-word {
  /* top: 55%; */
  text-align: center;
  font-size: 3rem;
  /* color: rgb(244, 223, 106); */
  color: #ffd22f;
  text-shadow: 2px 2px 4px rgba(0,0,0,1);
  font-weight: bold;
  font-family: 'cwTeXYen';
	letter-spacing: 0.25rem;
  /* -webkit-text-stroke: 0.5px #f4eee0; */
  /* text-shadow: 0px 0px 3px aliceblue; */
  /* text-shadow: 2px 2px 4px rgba(0,0,0,1); */
  /* margin: 20px; */
  line-height: 1;
	padding: 0.35rem;
  background-color: #f4eee0;
  border-radius: 10px;
  /* text-shadow: */
  /* 0.03em 0.03em 0.05em rgba(244,223,106, 0.2), */
  /* 0.01em 0.01em 0 #80732a, */
  /* 0.02em 0.02em 0 #80732a, */
  /* 0.03em 0.03em 0 #80732a, */
  /* 0.03em 0.04em 0 #80732a, */
  /* 0.05em 0.05em 0 #80732a, */
  /* 0.05em 0.06em 0 #80732a, */
  /* 0.05em 0.07em 0 #80732a; */

  /* text-shadow:
  -1px -1px 0 #474646,
  1px -1px 0 #474646,
  -1px 1px 0 #474646,
  1px 1px 0 #474646; */
}

input[type="text"],
input[type="url"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
textarea {
  font-size: 16px;
  line-height: normal;
}

@keyframes slideLeft {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* 平板適配 */
@media screen and (min-width: 481px) and (max-width: 1024px) {
  .input-row {
    display: flex;
    align-items: center;
    padding: 12px;
    gap: 12px;
  }

  .paste-icon,
  .save-icon {
    width: 32px;
    height: 32px;
  }
}

/* 針對最小寬度為 768px 的 */
@media (min-width: 768px) {
  .body {
    flex-direction: row;
    justify-content: space-between;
  }

  .mainBody {
    width: calc(100% - 120px);
    margin: 20px 30px;
  }

  .ad {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100px;
  }

  .tablinks,
  .subtablinks {
    flex: 1;
  }

  .footer-main {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px 100px;
    padding: 30px 20px;
  }

  .footer-left,
  .footer-right {
    width: auto;
    text-align: left;
    margin-bottom: 0;
  }

  .footer-left {
    font-size: 1.65vmin;
  }

  .footer-right {
    align-items: flex-start;
  }

  .copyright {
    font-size: 1.7vmin;
  }

  .marquee-container {
    right: -30%;
  }

  .top-animation {
    transform: scale(0.9);
    top: -5%;
    right: 5%;
  }

  .bottom-animation {
    transform: scale(0.9);
    bottom: 30%;
    right: 5%;
  }

  .marquee-word {
    /* font-size: 2rem; */
    right: 30%;
  }
}

/* 手機 */
@media (max-width: 480px) {
  .mainBody {
    width: calc(100% - 20px);
    margin: 20px 30px;
  }

  .bulletin-board {
    font-size: 0.9rem;
  }

  .input-row {
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 8px;
    position: relative;
  }

  input[type="url"] {
    flex: 1;
    min-width: 0; /* 防止 flex 子項溢出 */
    font-size: 16px;
    padding: 10px;
  }

  .paste-icon,
  .save-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0; /* 防止圖標被壓縮 */
  }

  .save-icon-wrapper {
    position: relative;
    z-index: 2;
  }
}

  .marquee-container {
    right: -20%;
  }

  .top-animation {
    transform: scale(0.8);
    top: -10%;
    right: 8%;
  }

  .bottom-animation {
    transform: scale(0.65);
    bottom: 38%;
    right: 5%;
  }

  .marquee-word {
    font-size: 2rem;
    right: 20%;
  }

/* 針對橫屏模式 */
@media screen and (orientation: landscape) and (max-height: 480px) {
  .input-row {
    padding: 8px 16px;
  }

  input[type="url"] {
    padding: 8px 12px;
  }
}

/* 高 DPI 設備優化 */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  input[type="url"] {
    border-width: 0.5px;
  }
}
